import './scss/style.scss';
import 'bootstrap';
import '../node_modules/overlayscrollbars/css/OverlayScrollbars.min.css';

import { Swiper, Autoplay, Pagination } from 'swiper/js/swiper.esm.js';

import '../node_modules/overlayscrollbars/js/jquery.overlayScrollbars.min.js';

Swiper.use([Autoplay, Pagination]);

$('#scrollbars-catalog').overlayScrollbars({
    className: "os-theme-light",
    resize: "vertical"
});

$('[data-tooltip="tooltip"]').tooltip();

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiper = new Swiper('#slider-main', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });
}

$(document).on('click', '.btn-locate-us-google-maps', function(event) {
    event.preventDefault();

    var lat = $(this).data('lat');
    var lng = $(this).data('lng');
    var item = $(this).data('item');

    if (item_old != null) infowindow[item_old].close();

    map.setZoom(15);
    map.setCenter({ lat: lat, lng: lng });

    infowindow[item].open(map, marker[item]);

    item_old = item;

});

$(document).on('click', '.block-partners .btn-primary', function(event) {
    event.preventDefault();
    if($(".block-partners").hasClass("container-short")) {
        $('.block-partners').removeClass('container-short');
        $('.block-partners .btn-primary').text('Ver menos');
    }else{
        $('.block-partners').addClass('container-short');
        $('.block-partners .btn-primary').text('Ver Más');
         $('html, body').animate({ scrollTop: $(".block-partners").offset().top }, 1000);
    }
});